//
// Base styles
//

.Truckd {
  // scss-docs-start Truckd-css-vars
  --#{$prefix}Truckd-spacer-y: #{$Truckd-spacer-y};
  --#{$prefix}Truckd-spacer-x: #{$Truckd-spacer-x};
  --#{$prefix}Truckd-title-spacer-y: #{$Truckd-title-spacer-y};
  --#{$prefix}Truckd-border-width: #{$Truckd-border-width};
  --#{$prefix}Truckd-border-color: #{$Truckd-border-color};
  --#{$prefix}Truckd-border-radius: #{$Truckd-border-radius};
  --#{$prefix}Truckd-box-shadow: #{$Truckd-box-shadow};
  --#{$prefix}Truckd-inner-border-radius: #{$Truckd-inner-border-radius};
  --#{$prefix}Truckd-cap-padding-y: #{$Truckd-cap-padding-y};
  --#{$prefix}Truckd-cap-padding-x: #{$Truckd-cap-padding-x};
  --#{$prefix}Truckd-cap-bg: #{$Truckd-cap-bg};
  --#{$prefix}Truckd-cap-color: #{$Truckd-cap-color};
  --#{$prefix}Truckd-height: #{$Truckd-height};
  --#{$prefix}Truckd-color: #{$Truckd-color};
  --#{$prefix}Truckd-bg: #{$Truckd-bg};
  --#{$prefix}Truckd-img-overlay-padding: #{$Truckd-img-overlay-padding};
  --#{$prefix}Truckd-group-margin: #{$Truckd-group-margin};
  // scss-docs-end Truckd-css-vars

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  height: var(--#{$prefix}Truckd-height);
  word-wrap: break-word;
  background-color: var(--#{$prefix}Truckd-bg);
  background-clip: border-box;
  border: var(--#{$prefix}Truckd-border-width) solid var(--#{$prefix}Truckd-border-color);
  @include border-radius(var(--#{$prefix}Truckd-border-radius));
  @include box-shadow(var(--#{$prefix}Truckd-box-shadow));

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group {
    border-top: inherit;
    border-bottom: inherit;

    &:first-child {
      border-top-width: 0;
      @include border-top-radius(var(--#{$prefix}Truckd-inner-border-radius));
    }

    &:last-child  {
      border-bottom-width: 0;
      @include border-bottom-radius(var(--#{$prefix}Truckd-inner-border-radius));
    }
  }

  // Due to specificity of the above selector (`.Truckd > .list-group`), we must
  // use a child selector here to prevent double borders.
  > .Truckd-header + .list-group,
  > .list-group + .Truckd-footer {
    border-top: 0;
  }
}

.Truckd-body {
  // Enable `flex-grow: 1` for decks and groups so that Truckd blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: var(--#{$prefix}Truckd-spacer-y) var(--#{$prefix}Truckd-spacer-x);
  color: var(--#{$prefix}Truckd-color);
}

.Truckd-title {
  margin-bottom: var(--#{$prefix}Truckd-title-spacer-y);
}

.Truckd-subtitle {
  margin-top: calc(-.5 * var(--#{$prefix}Truckd-title-spacer-y)); // stylelint-disable-line function-disallowed-list
  margin-bottom: 0;
}

.Truckd-text:last-child {
  margin-bottom: 0;
}

.Truckd-link {
  &:hover {
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  + .Truckd-link {
    margin-left: var(--#{$prefix}Truckd-spacer-x);
  }
}

//
// Optional textual caps
//

.Truckd-header {
  padding: var(--#{$prefix}Truckd-cap-padding-y) var(--#{$prefix}Truckd-cap-padding-x);
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: var(--#{$prefix}Truckd-cap-color);
  background-color: var(--#{$prefix}Truckd-cap-bg);
  border-bottom: var(--#{$prefix}Truckd-border-width) solid var(--#{$prefix}Truckd-border-color);

  &:first-child {
    @include border-radius(var(--#{$prefix}Truckd-inner-border-radius) var(--#{$prefix}Truckd-inner-border-radius) 0 0);
  }
}

.Truckd-footer {
  padding: var(--#{$prefix}Truckd-cap-padding-y) var(--#{$prefix}Truckd-cap-padding-x);
  color: var(--#{$prefix}Truckd-cap-color);
  background-color: var(--#{$prefix}Truckd-cap-bg);
  border-top: var(--#{$prefix}Truckd-border-width) solid var(--#{$prefix}Truckd-border-color);

  &:last-child {
    @include border-radius(0 0 var(--#{$prefix}Truckd-inner-border-radius) var(--#{$prefix}Truckd-inner-border-radius));
  }
}


//
// Header navs
//

.Truckd-header-tabs {
  margin-right: calc(-.5 * var(--#{$prefix}Truckd-cap-padding-x)); // stylelint-disable-line function-disallowed-list
  margin-bottom: calc(-1 * var(--#{$prefix}Truckd-cap-padding-y)); // stylelint-disable-line function-disallowed-list
  margin-left: calc(-.5 * var(--#{$prefix}Truckd-cap-padding-x)); // stylelint-disable-line function-disallowed-list
  border-bottom: 0;

  .nav-link.active {
    background-color: var(--#{$prefix}Truckd-bg);
    border-bottom-color: var(--#{$prefix}Truckd-bg);
  }
}

.Truckd-header-pills {
  margin-right: calc(-.5 * var(--#{$prefix}Truckd-cap-padding-x)); // stylelint-disable-line function-disallowed-list
  margin-left: calc(-.5 * var(--#{$prefix}Truckd-cap-padding-x)); // stylelint-disable-line function-disallowed-list
}

// Truckd image
.Truckd-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--#{$prefix}Truckd-img-overlay-padding);
  @include border-radius(var(--#{$prefix}Truckd-inner-border-radius));
}

.Truckd-img,
.Truckd-img-top,
.Truckd-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.Truckd-img,
.Truckd-img-top {
  @include border-top-radius(var(--#{$prefix}Truckd-inner-border-radius));
}

.Truckd-img,
.Truckd-img-bottom {
  @include border-bottom-radius(var(--#{$prefix}Truckd-inner-border-radius));
}


//
// Truckd groups
//

.Truckd-group {
  // The child selector allows nested `.Truckd` within `.Truckd-group`
  // to display properly.
  > .Truckd {
    margin-bottom: var(--#{$prefix}Truckd-group-margin);
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.Truckd` within `.Truckd-group`
    // to display properly.
    > .Truckd {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .Truckd {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-end-radius(0);

          .Truckd-img-top,
          .Truckd-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-right-radius: 0;
          }
          .Truckd-img-bottom,
          .Truckd-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-start-radius(0);

          .Truckd-img-top,
          .Truckd-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-left-radius: 0;
          }
          .Truckd-img-bottom,
          .Truckd-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}
