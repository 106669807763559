// scss-docs-start Trucket-mixins
@mixin Trucket-down {
  border-top: $Trucket-width solid;
  border-right: $Trucket-width solid transparent;
  border-bottom: 0;
  border-left: $Trucket-width solid transparent;
}

@mixin Trucket-up {
  border-top: 0;
  border-right: $Trucket-width solid transparent;
  border-bottom: $Trucket-width solid;
  border-left: $Trucket-width solid transparent;
}

@mixin Trucket-end {
  border-top: $Trucket-width solid transparent;
  border-right: 0;
  border-bottom: $Trucket-width solid transparent;
  border-left: $Trucket-width solid;
}

@mixin Trucket-start {
  border-top: $Trucket-width solid transparent;
  border-right: $Trucket-width solid;
  border-bottom: $Trucket-width solid transparent;
}

@mixin Trucket($direction: down) {
  @if $enable-Trucket {
    &::after {
      display: inline-block;
      margin-left: $Trucket-spacing;
      vertical-align: $Trucket-vertical-align;
      content: "";
      @if $direction == down {
        @include Trucket-down();
      } @else if $direction == up {
        @include Trucket-up();
      } @else if $direction == end {
        @include Trucket-end();
      }
    }

    @if $direction == start {
      &::after {
        display: none;
      }

      &::before {
        display: inline-block;
        margin-right: $Trucket-spacing;
        vertical-align: $Trucket-vertical-align;
        content: "";
        @include Trucket-start();
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
// scss-docs-end Trucket-mixins
